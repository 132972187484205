
/* eslint-disable global-require */
// eslint-disable-next-line no-unused-expressions

import Vue from 'vue';
import Component from 'vue-class-component';
import VueApexCharts from 'vue-apexcharts';
import VxTimeline from '@/components/timeline/VxTimeline.vue';
import userModule from '@/store/modules/user/user.module';
import NotificationTimeline from '@/modules/organisation/components/NotificationTimeline.vue';
import arriveSvg from '@/assets/images/svg/guest-arriving.svg';
import departSvg from '@/assets/images/svg/guest-departing.svg';
import RadialOptions from '@/plugins/charts/radial.chart';
import SvgIcon from '@/components/svg/Check-in-icon.svg';
import { mapGetters, mapState } from 'vuex';
import { Ref, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { AccommodationDto } from '../../../app/entities/tenant/accommodation.dto';
import { tenantModule } from '../../../store/modules/tenant/tenant.module';
import service from '../../../services/app-http-client';

@Component({
  components: { VueApexCharts, VxTimeline, NotificationTimeline, arriveSvg, departSvg, SvgIcon },
  computed: {
    ...mapState('tenant', ['accommodations']),
    ...mapState('tenant', ['bookings']),
    ...mapGetters('tenant', ['accommodationList']),
  },
})
export default class OrganisationHomeView extends Vue {
  @Ref() readonly chart!: typeof VueApexCharts;

  readonly accommodationList!: AccommodationDto[];

  loaded = false;

  chartData: any = {
    series: [
      {
        name: 'PENDING',
        data: [],
      },
      {
        name: 'PRE CHECKED-IN',
        data: [],
      },
      // {
      //   name: 'CONFIRMED',
      //   data: [],
      // },
      {
        name: 'CHECKED-IN',
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
          tools: {
            download: true,
            selection: true,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            customIcons: [],
          },
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 1025,
          options: {
            legend: {
              position: 'bottom',
              offsetX: 0,
              offsetY: 10,
            },
            chart: {
              width: 500,
              offsetX: -25,
              offsetY: 10,
            },
          },
        },
      ],

      colors: ['#DC2626', '#F59E0B', '#84CC16'],

      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: false,
        },
      },
      xaxis: {
        type: 'datetime',
        categories: [],
        tickPlacement: 'on',
        tickAmount: 5,
        axisTicks: {
          offsetX: 0,
        },
      },

      legend: {
        position: 'right',
        offsetY: 80,
        offsetX: -10,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  accommodations!: any[];

  timelineData = [
    {
      title: 'test',
      color: 'red-500',
      icon: 'faUser',
      desc: 'Testing 123',
      time: '13:34',
    },
  ];

  guestCounts = {
    countIn: 0,
    countOut: 0,
  };

  noBookings = false;

  filter = {
    accommodation: '',
  };

  @Watch('accommodationList')
  selectHandler(): void {
    if (this.accommodationList.length == 1) {
      this.filter.accommodation = this.accommodationList[0].value;
      this.getChart();
    }
  }
  get productsOrder() {
    return {
      series: this.series,
      chartOptions: RadialOptions,
    };
  }
  series: any[] = [];

  get user() {
    return userModule.user;
  }

  async getChart() {
    const load = this.$vs.loading({ target: this.$refs.chart, type: 'circles' });
    this.getCount();
    const data = await tenantModule.accommodationQuery(this.filter.accommodation);
    this.updateChart(data);
    load.close();
  }

  async updateChart(data: Record<string, any>[]) {
    const xAxis: string[] = [];
    (this.chartData.series = [
      {
        name: 'PENDING',
        data: [],
      },
      {
        name: 'PRE CHECKED-IN',
        data: [],
      },
      // {
      //   name: 'CONFIRMED',
      //   data: [],
      // },
      {
        name: 'CHECKED-IN',
        data: [],
      },
    ]),
      data.forEach((x: any) => {
        xAxis.push(dayjs(x.day).format('MM/DD/YYYY') + ' GMT');

        this.chartData.series[0].data.push(parseInt(x.pending ?? 0));

        this.chartData.series[1].data.push(parseInt(x.precheckin ?? 0));

        // this.chartData.series[2].data.push(parseInt(x.confirmed ?? 0));

        this.chartData.series[2].data.push(parseInt(x.checkedin ?? 0));
      });

    (this.chartData.chartOptions = {
      ...this.chartData.chartOptions,
      ...{
        xaxis: {
          type: 'datetime',
          categories: xAxis,
          tickPlacement: 'on',
          tickAmount: 5,
          axisTicks: {
            offsetX: 0,
          },
        },
      },
    }),
      /*     this.chartData.chartOptions.xaxis.categories = xAxis;
       */
      // await this.chart.updateOptions({
      //   xaxis: {
      //     categories: xAxis,
      //   },
      // });

      // await this.chart.updateSeries(
      //   [
      //     {
      //       data: this.chartData.series,
      //     },
      //   ],
      //   false,
      //   true,
      // );

      (this.loaded = true);
  }
  /*   async getCount(filter?: string) {
    console.log(filter);
    const { data } = await service.get('booking/total/query');
    const [bookingDates] = data.bookingDates;
    this.guestCounts = bookingDates;
  } */
  async getCount(_filter?: string) {
    //hey hey how you doing?
    const { data } = await service.get(`booking/totalGuests/query/${this.filter.accommodation}`);
    const guestsDates = data;
    if (guestsDates) {
      this.guestCounts = guestsDates;
    } else {
      this.noBookings = true;
    }
  }
  async mounted() {
    this.selectHandler();
  }
}
