<route>
{
  "meta": {
    "requiresAuth": true,
    "breadcrumb": [
          { "title": "Home", "url": "/organisation/dashboard" },
          { "title": "Dashboard", "active": true }
      ],
    "title": "Dashboard",
    "resource": "tenant",
    "action": ["read"]

  }
}
</route>
<template>
  <div class="organisation">
    <OrganisationHomeView />
  </div>
</template>

<script>
// @ is an alias to /src
import OrganisationHomeView from '@/modules/organisation/pages/OrganisationHomeView.vue';

export default {
  name: 'dashboard',
  components: {
    OrganisationHomeView,
  },
  layout: 'AppMain',
};
</script>
