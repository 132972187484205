import { appModule } from '../../store/modules/app.module';

export default {
  chart: {
    height: 390,
    type: 'radialBar',
  },
  plotOptions: {
    radialBar: {
      offsetY: 0,
      startAngle: 0,
      endAngle: 270,
      hollow: {
        margin: 5,
        size: '30%',
        background: 'transparent',
        image: undefined,
      },
      dataLabels: {
        name: {
          show: true,
        },
        value: {
          show: true,
          color: appModule.darkMode ? '#eeeeee' : '#636a71',
          offsetY: 11,
        },
        total: {
          show: true,
          label: 'Total',
          color: appModule.darkMode ? '#eeeeee' : '#636a71',
          fontWeight: 700,
          formatter: function ({ config }: any) {
            return config.series.reduce((a: any, b: any) => parseInt(a) + parseInt(b));
          },
        },
      },
    },
  },

  colors: ['#E02424', '#008489', '#031926', '#FF9F43'],
  fill: {
    type: 'gradient',
    gradient: {
      // enabled: true,
      shade: 'dark',
      type: 'vertical',
      shadeIntensity: 0.5,
      gradientToColors: ['#F2A6A6', '#99FCFF', '#1296E2', '#F4E9CD'],
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  labels: ['Pending', 'Confirmed', 'Checked In', 'Checked Out'],
  legend: {
    show: true,
    floating: true,
    fontSize: '15px',
    position: 'left',
    fontWeight: 500,
    fontFamily: 'Poppins',
    offsetX: 80,
    offsetY: 0,
    labels: {
      useSeriesColors: true,
    },
    markers: {
      size: 0,
    },
    formatter: function (seriesName: any, opts: any) {
      return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex];
    },
    itemMargin: {
      vertical: 3,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          show: false,
        },
      },
    },
  ],
  stroke: {
    lineCap: 'round',
  },
};
