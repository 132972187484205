
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IMessage } from '../../plugins/event-bus/notification/notification.constants';

@Component({ name: 'VxTimeline' })
export default class VxTimeline extends Vue {
  @Prop({ required: true }) data: IMessage[];
}
