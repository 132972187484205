
import { Component, Vue } from 'vue-property-decorator';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import VxTimeline from '@/components/timeline/VxTimeline.vue';
import { Socket } from 'vue-socket.io-extended';
import { IMessage } from '../../../plugins/event-bus/notification/notification.constants';
import { mapGetters, mapState } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { Loading } from '@/modules/auth/store/auth.service';
import { Notifications } from '@/store/modules/notification.module';

@Component({
  components: {
    StatisticsCardLine,
    VxTimeline,
    VuePerfectScrollbar,
  },
  computed: {
    ...mapGetters('notification', ['orderedMessages']),
  },
})
export default class NotificationTimeline extends Vue {
  orderedMessages!: IMessage[];
  @Loading
  public async deleteNotifications() {
    var b = await Notifications.deleteNotifications(tenantModule.tenant.id as string);
    Notifications.setMessage([]);
    console.log(this.orderedMessages);
  }
  @Socket() // --> listens to the event by method name, e.g. `connect`
  connect() {
    /*     console.log('sockets');
     */
  }
}
